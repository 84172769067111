import { version } from "./version";

/** @internal */
export type Logger = {
  log: (message: string | object) => Promise<void>;
};

/** @internal */
function getVisit() {
  // @ts-ignore
  let visit = window?.tracking?.visit();
  if (visit) {
    return visit;
  }
  const cookie = document.cookie
    .split(";")
    .find((c) => c.trim().startsWith("visit"));

  if (cookie) {
    visit = cookie.split("=")[1].trim();
  }
  return visit;
}

/** @internal */
export function initLogging(): Logger {
  const sendRequest = async (payload: any) =>
    await fetch(getLoggingUrl(window.location.hostname), {
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });

  const logCache: { [key: string]: boolean } = {};
  return {
    log: async (message: string | any) => {
      try {
        const messageKey =
          typeof message === "string" ? message : (message.message as string);
        if (logCache[messageKey]) {
          return;
        }
        logCache[messageKey] = true;
        let messageObject = {} as any;
        if (typeof message === "string") {
          messageObject.message = message;
        } else {
          messageObject = { ...message };
        }
        await sendRequest(
          JSON.stringify([
            {
              common: {
                attributes: {
                  host: window.location.href,
                  agent: window.navigator.userAgent,
                  serviceName: "ab-reader",
                  visit: getVisit(),
                  version,
                },
              },
              logs: [{ ...messageObject, logtype: "browser" }],
            },
          ])
        );
      } catch (e: any) {
        console.error("ab-reader failed sending logs", e?.message);
      }
    },
  };
}

function getLoggingUrl(hostname: string): string {
  let scriptSrc = "https://nrlogger-prod.cf-exp.vpsvc.com?partition=exp";

  if (hostname === "localhost" || hostname.startsWith("staging")) {
    scriptSrc = "https://nrlogger-dev.cf-exp.vpsvc.com?partition=exp";
  }

  return scriptSrc;
}
