import { Experiment, Feature } from "./experiment";
import { isWindowUndefined } from "./utils";
import { ensureUserTestId } from "@vp/ab-test-cookie";
import { PageParameters } from "./pageParameter";

const EXPHUB_LS_KEY = "expCtx";
const FEATURE_LS_KEY = "expFlagCtx";
const IS_QA_COOKIE_KEY = "expIsQA";
const LS_IMPRESSION_KEY = "exp-ab-impression-v2";
const LS_CONTEXT_INIT_KEY = "expCtxInit";
const LS_EXP_TTL = 120000;
const LS_FLG_TTL = 600000;
const LS_TTL_QA = 20000;

const overrides = {
  experiments: new Map<string, string>([]),
  features: new Map<string, string>([]),
};

interface TrackProperties extends PageParameters {
  experimentName: string;
  variationName: string;
  abReaderVersion: string;
  impressionEventVersion: number;
}

interface TrackFeatureProperties extends PageParameters {
  flagName: string;
  passes: boolean;
  ruleId: string;
  abReaderVersion: string;
}

const bypassExperimentation = () => {
  if (isWindowUndefined()) {
    return false;
  }
  const bypass = window.location.search?.includes("exp_param_ignore");
  if (bypass) console.debug("(exp_param_ignore) Bypass experimentation");
  return bypass;
};

const loadOverrides = () => {
  if (
    isWindowUndefined() ||
    !["localhost", "127.0.0.1"].includes(window.location.hostname)
  ) {
    return;
  }
  const search = window.location.search;
  const experimentOverridePrefix = "exp_e_";
  const flagOverridePrefix = "exp_f_";
  const isOverride =
    search?.includes(experimentOverridePrefix) ||
    search?.includes(flagOverridePrefix);
  if (isOverride) {
    const paramValue = new URLSearchParams(search);
    paramValue.forEach((value, key) => {
      if (key.startsWith(experimentOverridePrefix)) {
        overrides.experiments.set(
          key.replace(experimentOverridePrefix, ""),
          value
        );
      }
      if (key.startsWith(flagOverridePrefix)) {
        overrides.features.set(key.replace(flagOverridePrefix, ""), value);
      }
    });
  }
};

const getInitTimeout = () => {
  if (isWindowUndefined()) {
    return null;
  }
  const overrideQSParam = "overrideInitTimeout";
  const override = window.location.search?.includes(overrideQSParam);
  if (override) {
    console.debug(`(${overrideQSParam}) Override init timeout`);
    const params = new URLSearchParams(window.location.search);
    return parseInt(params.get(overrideQSParam) || "");
  }
  return null;
};
const LS_CONTEXT_INIT_TIMEOUT = getInitTimeout() || 60 * 60 * 1000;

const getContext = (name: string) => {
  return localStorage.getItem(name) || "";
};

function getCookieValue(name: string): string | null {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
}

const logErrorMsg = (name: string, error: any) => {
  console.warn(`AB reader failed in ${name}`, error);
};

/** @internal */
export const getAllExperiments = (): Experiment[] => {
  try {
    if (bypassExperimentation() || !isValidContext()) {
      return [];
    }
    const expCtx = getContext(EXPHUB_LS_KEY);
    if (expCtx) {
      const experiments = deserializeCtx(expCtx, "|", experimentBuilder);
      overrides.experiments.forEach((varKey, exp) => {
        let found = false;
        experiments.forEach((entry) => {
          if (entry.experimentKey === exp) {
            found = true;
            entry.variationKey = varKey;
          }
        });
        if (!found) {
          experiments.push({ experimentKey: exp, variationKey: varKey });
        }
      });
      return experiments;
    }
  } catch (e) {
    logErrorMsg("getAllExperiments", e);
  }
  return [];
};

/** @internal */
export const getVariation = (experimentKey: string): string | null => {
  try {
    const overrideSetting = overrides.experiments.get(experimentKey);
    if (overrideSetting) {
      return overrideSetting;
    }

    const experiments = getAllExperiments();
    const experiment = experiments.find(
      (e: Experiment) => e.experimentKey === experimentKey
    );
    return experiment ? experiment.variationKey : null;
  } catch (e) {
    logErrorMsg("getVariation", e);
    return null;
  }
};

/** @internal */
const getAllFeatures = (): Feature[] => {
  try {
    if (bypassExperimentation() || !isValidContext()) {
      return [];
    }
    const featuresCookie = getContext(FEATURE_LS_KEY);
    if (featuresCookie) {
      return deserializeCtx(featuresCookie, "|", flagBuilder);
    }
  } catch (e) {
    logErrorMsg("getAllFeatures", e);
  }
  return [];
};

/** @internal */
export const checkFeature = (featureName: string): Feature | null => {
  try {
    const overrideSetting = overrides.features.get(featureName);
    if (overrideSetting) {
      return {
        enabled: overrideSetting,
        featureName,
        ruleName: "override",
      };
    }

    const features = getAllFeatures();
    const feature = features.find(
      (e: Feature) => e.featureName === featureName
    );
    return feature ?? null;
  } catch (e) {
    logErrorMsg("getVariation", e);
    return null;
  }
};

/** @internal */
export const isAvailable = (): boolean => {
  try {
    if (overrides.experiments.size > 0 || overrides.features.size > 0) {
      return true;
    }
    let cookie = getContext(EXPHUB_LS_KEY);
    return !!cookie;
  } catch (e) {
    logErrorMsg("isAvailable", e);
    return false;
  }
};

/**
 * Only for backward compatibility
 * @internal
 * */
export const whenAvailable = (
  callback: (available?: boolean) => void
): void => {
  callback(isAvailable());
};

/** @internal */
export const getTestUserId = (): string => {
  return ensureUserTestId();
};

/** @internal */
export function fireImpression(
  experimentKey: string,
  variationKey: string,
  pageParameters?: PageParameters | null,
  abReaderVersion?: string
) {
  if (!experimentKey || !variationKey) {
    logErrorMsg(
      "fireImpression",
      "Invalid input parameters for the fireImpression"
    );
    return false;
  }
  let trackProperties: TrackProperties = {
    experimentName: experimentKey,
    variationName: variationKey,
    impressionEventVersion: 3,
    abReaderVersion: abReaderVersion || "3",
  };
  if (pageParameters) {
    if (
      pageParameters.pageName &&
      pageParameters.pageSection &&
      pageParameters.pageStage
    ) {
      trackProperties = {
        ...trackProperties,
        pageName: pageParameters.pageName,
        pageSection: pageParameters.pageSection,
        pageStage: pageParameters.pageStage,
      };
    } else {
      logErrorMsg("fireImpression", "Page parameters format is wrong");
    }
  }
  try {
    if (isImpressionFired(experimentKey, variationKey)) {
      return false;
    }
    const isQA = getCookieValue(IS_QA_COOKIE_KEY) === "1";
    trackInternal("Experiment Viewed", trackProperties);
    setImpressionFired(
      experimentKey,
      variationKey,
      isQA ? LS_TTL_QA : LS_EXP_TTL
    );
    return true;
  } catch (error) {
    logErrorMsg("fireImpression", error);
    return false;
  }
}

/** @internal */
export function fireFeatureImpression(
  featureName: string,
  ruleName: string,
  enabled: string,
  abReaderVersion?: string
) {
  if (!featureName || !ruleName || !enabled) {
    logErrorMsg(
      "fireImpression",
      "Invalid input parameters for the fireFeatureImpression"
    );
    return false;
  }
  try {
    if (isImpressionFired(featureName, enabled)) {
      return false;
    }

    // Don't send tracking for launched variations
    if (ruleName === "disabled") {
      return false;
    }

    let trackProperties: TrackFeatureProperties = {
      flagName: featureName,
      passes: enabled === "true",
      ruleId: ruleName,
      abReaderVersion: abReaderVersion || "3",
    };
    const isQA = getCookieValue(IS_QA_COOKIE_KEY) === "1";
    trackInternal("Experiment Flag Viewed", trackProperties);
    setImpressionFired(featureName, enabled, isQA ? LS_TTL_QA : LS_FLG_TTL);
    return true;
  } catch (error) {
    logErrorMsg("fireFeatureImpression", error);
    return false;
  }
}

const deserializeCtx = <T>(
  rawContext: string,
  splitter: string,
  builder: (entry: string) => { key: string; entry: T } | null
): T[] => {
  const entries: T[] = [];
  const exisitngIds = new Set<string>();

  const versions = rawContext.split("~");

  if (versions !== null && versions.length === 2) {
    const rawContextString = versions[1];
    const rawEntries = rawContextString.split(splitter);

    rawEntries.forEach((entryString: string) => {
      const entry = builder(entryString);
      if (entry?.key) {
        if (exisitngIds.has(entry.key)) {
          console.debug(
            `Exp: context has more than one instance of key ${entry.key}`,
            rawContext
          );
        } else {
          exisitngIds.add(entry.key);
          entries.push(entry.entry);
        }
      }
    });
  }
  return entries;
};

const experimentBuilder = (
  entry: string
): { key: string; entry: Experiment } | null => {
  const experimentData = entry.split(":");
  if (experimentData.length != 2) {
    console.debug("Exp context is malformed: ", entry);
    return null;
  }
  return {
    key: experimentData[0].trim(),
    entry: {
      experimentKey: experimentData[0].trim(),
      variationKey: experimentData[1].trim(),
    },
  };
};

const flagBuilder = (entry: string): { key: string; entry: Feature } | null => {
  const featureData = entry.split("^");
  if (featureData.length != 3) {
    console.debug("Features context is malformed: ", entry);
    return null;
  }
  return {
    key: featureData[0].trim(),
    entry: {
      featureName: featureData[0].trim(),
      ruleName: featureData[1].trim(),
      enabled: featureData[2].trim(),
    },
  };
};

const requestContext = async () => {
  try {
    const testUserId = ensureUserTestId();
    const requestTime = Date.now();
    const result = await fetch(
      `https://context-init-prod.cf-exp.vpsvc.com/?requestUrl=${encodeURIComponent(
        window.location.origin
      )}&testUserId=${testUserId}`
    );
    const resultObject: { name: string; destination: string; value: string }[] =
      await result.json();
    resultObject.forEach((entry) => {
      if (entry.destination === "client" && entry.name === "Set-Cookie") {
        document.cookie = entry.value;
      }
      if (entry.destination === "client" && entry.name === "Add-Script") {
        const regex =
          /localStorage\.setItem\(\s*"(?<key>\w+)"\s*,\s*"(?<value>[^\s]+)"\s*\)/g;
        let match = null;
        let counter = 5;
        do {
          match = regex.exec(entry.value)?.groups;
          if (match?.key) {
            localStorage.setItem(match.key, match.value);
          }
        } while (match != null && --counter > 0);
      }
    });
    window.dispatchEvent(new Event("variationsResolved"));
    localStorage.setItem(
      "expCtxInit",
      JSON.stringify({ testUserId, timestamp: requestTime })
    );
  } catch (e) {
    console.warn("Experiment conext initialization failed");
  }
};

type ImpressionCache = {
  testUserId: string;
  experimentsV2?: {
    [k: string]: {
      _ttl: number;
      variation: string;
    };
  };
};

function getImpressionCache(): ImpressionCache {
  const newImpressionCache: ImpressionCache = {
    testUserId: ensureUserTestId(),
    experimentsV2: {},
  };
  try {
    const impressionCache = JSON.parse(
      localStorage.getItem(LS_IMPRESSION_KEY) || "{}"
    ) as ImpressionCache;
    if (impressionCache && impressionCache.testUserId === ensureUserTestId()) {
      const currentDate = new Date().getTime();
      impressionCache.experimentsV2 = Object.entries(
        impressionCache.experimentsV2 || {}
      ).reduce((accu, [experiment, eCache]) => {
        if (eCache._ttl > currentDate) {
          accu[experiment] = eCache;
        }
        return accu;
      }, {} as { [k: string]: any });

      return impressionCache;
    }
  } catch (error) {
    logErrorMsg("fireImpression", error);
  }
  return newImpressionCache;
}

function isImpressionFired(experimentKey: string, variationKey: string) {
  const impressionCache = getImpressionCache();
  return (
    impressionCache.experimentsV2![experimentKey]?.variation === variationKey
  );
}

function setImpressionFired(
  experimentKey: string,
  variationKey: string,
  ttl: number = LS_EXP_TTL
) {
  let impressionCache = getImpressionCache();
  impressionCache.experimentsV2![experimentKey] = {
    variation: variationKey,
    _ttl: new Date().getTime() + ttl,
  };
  localStorage.setItem(LS_IMPRESSION_KEY, JSON.stringify(impressionCache));
}

function trackInternal(eventName: string, properties: any) {
  if (window?.tracking?.track) {
    sendTrackEvent();
  } else {
    const start = new Date().getTime();
    window.addEventListener("trackingReady", function () {
      sendTrackEvent(new Date().getTime() - start);
    });
  }

  function sendTrackEvent(delay?: number) {
    // https://app.segment.com/vistaprint/protocols/tracking-plans/rs_1Uvr60Jg3ROZK4boO6jKvuUj2du
    properties.trackingDelay = delay ?? 0;
    window.tracking.track(eventName, properties);
  }
}

function isValidContext(): boolean {
  const rawContextInitData = localStorage.getItem(LS_CONTEXT_INIT_KEY);
  if (!rawContextInitData) {
    console.debug("ab-reader: unknown context init details");
    return true;
  }
  const rawContext = JSON.parse(rawContextInitData);
  const testUserId = ensureUserTestId();
  if (testUserId !== rawContext.testUserId) {
    console.debug("ab-reader: mismatched user id");
    return false;
  }
  if (Date.now() - rawContext.timestamp > LS_CONTEXT_INIT_TIMEOUT) {
    console.debug("ab-reader: stale context");
    return false;
  }
  return true;
}

//It would be better if this is not an IIFE, instead use the script's onLoad attribute but that will require updating the npm package
(function onLoad() {
  if (!isWindowUndefined()) {
    loadOverrides();

    setTimeout(() => {
      window.abReaderInitialized = true;
      setInterval(() => {
        requestContext();
      }, LS_CONTEXT_INIT_TIMEOUT);

      if (!isAvailable() || !isValidContext()) {
        requestContext();
      }
    }, 200);
  }
})();
