import { Experiment, Feature } from "./experiment";
import { CoreAbReader } from "./types";
import { initLogging } from "./logging";
import { isWindowUndefined } from "./utils";
import { PageParameters } from "./pageParameter";
import { version } from "./version";

const getABReader = (): CoreAbReader =>
  window?.abReader || {
    getVariation: () => null,
    getAllExperiments: () => [],
    checkFeature: () => null,
    isAvailable: () => false,
    getTestUserId: () => null,
    fireImpression: () => false,
    fireFeatureImpression: () => false,
  };

const logger = initLogging();
let initialize: () => void = () => {};
export const setInitialize = (initializeMethod: () => void) => {
  initialize = initializeMethod;
};

export const scriptLoadedEventName = "ab-script-loaded";

const checkForInitializationCalled = (
  methodName: string,
  experimentKey?: string,
  silent: boolean = false
): boolean => {
  if (isWindowUndefined()) {
    return false;
  }
  const experimentKeyMessage = experimentKey
    ? ` with experiment key [${experimentKey}]`
    : "";
  if (!window.abReaderInitialized) {
    if (!silent) {
      logInternal({
        message: `Library not initialized before using method ${methodName}${experimentKeyMessage}.`,
      });
    }
    return false;
  }
  return true;
};

const failSafeAndLog = <T>(
  func: () => T,
  functionName: string,
  defaultValue: T
): T => {
  try {
    return func();
  } catch (error) {
    logInternal({
      message: `ab-reader library failed when calling ${functionName}.`,
      error: JSON.stringify(error),
    });
  }
  return defaultValue;
};

export const isAvailable = (): boolean => {
  if (!checkForInitializationCalled("isAvailable")) {
    return false;
  }
  return isAvailableInternal();
};

const isAvailableInternal = (): boolean => {
  return failSafeAndLog(
    () => window.abReader?.isAvailable() ?? false,
    "isAvailable",
    false
  );
};

const checkFeature = (featureName: string): Feature | null => {
  if (!checkForInitializationCompleted("checkFeature", featureName)) {
    return null;
  }
  return failSafeAndLog(
    () => getABReader().checkFeature(featureName),
    "checkFeature",
    null
  );
};

export const getVariation = (experimentKey: string): string | null => {
  if (!checkForInitializationCompleted("getVariation", experimentKey)) {
    return null;
  }
  return failSafeAndLog(
    () => getABReader().getVariation(experimentKey),
    "getVariation",
    null
  );
};

export const getAllExperiments = (): Experiment[] => {
  if (!checkForInitializationCompleted("getAllExperiments")) {
    return [];
  }
  return failSafeAndLog(
    getABReader().getAllExperiments,
    "getAllExperiments",
    []
  );
};

export const fireImpression = (
  experimentKey: string,
  variationKey: string,
  pageParameters?: PageParameters | null
): boolean => {
  return fireInternalImpression(
    () =>
      getABReader().fireImpression(
        experimentKey,
        variationKey,
        pageParameters || null,
        version
      ),
    "fireImpression"
  );
};

const fireFeatureImpression = (feature: Feature): boolean => {
  return fireInternalImpression(
    () =>
      getABReader().fireFeatureImpression(
        feature.featureName,
        feature.ruleName,
        feature.enabled,
        version
      ),
    "fireFeatureImpression"
  );
};

const fireInternalImpression = (
  coreFireImpression: () => boolean,
  methodName: string
): boolean => {
  if (isWindowUndefined()) {
    logInternal({
      message: `${methodName} called serverside. `,
      error: "Tracking is not available.",
    });
  }
  const fireImpressionInternal = () =>
    failSafeAndLog(coreFireImpression, methodName, false);

  const fireImpressionCallback = async () => {
    fireImpressionInternal();
    window.removeEventListener(scriptLoadedEventName, fireImpressionCallback);
  };

  if (!checkForInitializationCompleted(methodName, undefined, true)) {
    if (!window.abReaderInitialized) {
      initialize();
    }
    window.addEventListener(scriptLoadedEventName, fireImpressionCallback);
    return false;
  }
  return fireImpressionInternal();
};

export const getTestUserId = (): string | null => {
  if (!checkForInitializationCompleted("getTestUserId")) {
    return null;
  }
  return failSafeAndLog(getABReader().getTestUserId, "getTestUserId", null);
};

export const waitTillAvailable = (timeout?: number): Promise<boolean> =>
  new Promise<boolean>((resolve) => {
    whenAvailable((available) => resolve(!!available), timeout);
  });

export const whenAvailable = (
  callback: (available?: boolean) => void,
  timeout?: number
): void => {
  if (isWindowUndefined()) {
    callback(false);
    return;
  }

  if (isAvailableInternal()) {
    callback(true);
    return;
  }

  let isAvailableResolve: (value: any) => void;

  const available = new Promise((resolve) => {
    isAvailableResolve = resolve;
  });

  const scriptHandler = () => {
    window.removeEventListener(scriptLoadedEventName, scriptHandler);
    if (isAvailableInternal()) {
      isAvailableResolve(true);
    } else {
      initVariationsHandler();
    }
  };

  const initScriptHandler = () => {
    window.addEventListener(scriptLoadedEventName, scriptHandler);
  };

  const variationsResolvedEventName = "variationsResolved";
  const variationsHandler = () => {
    window.removeEventListener(variationsResolvedEventName, variationsHandler);
    isAvailableResolve(true);
  };

  const initVariationsHandler = () => {
    window.addEventListener(variationsResolvedEventName, variationsHandler);
  };

  const promises = [available];
  if (timeout) {
    promises.push(
      new Promise((resolve) => {
        setTimeout(resolve, timeout);
      })
    );
  }

  if (!window.abReader) {
    initScriptHandler();
  } else {
    initVariationsHandler();
  }

  Promise.race(promises).then(() => {
    callback(isAvailableInternal());
  });
};

export const activate = (experimentKey: string): string | null => {
  const variation = getVariation(experimentKey);
  if (variation) {
    fireImpression(experimentKey, variation);
  }
  return variation;
};

export const isFeatureEnabled = (featureName: string): boolean => {
  const feature = checkFeature(featureName);
  if (feature !== null) {
    fireFeatureImpression(feature);
    if (feature.enabled === "true") {
      return true;
    }
  }
  return false;
};

const checkForInitializationCompleted = (
  methodName: string,
  experimentKey?: string,
  silent: boolean = false
): boolean => {
  if (!checkForInitializationCalled(methodName, experimentKey, silent)) {
    return false;
  }
  const experimentKeyMessage = experimentKey
    ? ` with experiment key [${experimentKey}]`
    : "";
  if (window.abReader === undefined) {
    if (!silent) {
      logInternal({
        message: `ab-reader library not ready before using method ${methodName}${experimentKeyMessage}.`,
      });
    }
    return false;
  }
  return true;
};

export function logInternal(message: string | Object) {
  logger.log(message);
}
