import { isWindowUndefined } from "./utils";

export {
  activate,
  fireImpression,
  getAllExperiments,
  getTestUserId,
  getVariation,
  isAvailable,
  isFeatureEnabled,
  whenAvailable,
  waitTillAvailable,
} from "./main";
import { scriptLoadedEventName, setInitialize } from "./main";
import * as abReader from "./index";

export const initialize = () => {
  if (isWindowUndefined()) {
    return;
  }
  //if script has been injected
  if (window.abReaderInitialized) return;

  window.abReader = abReader;
  window.dispatchEvent(new CustomEvent(scriptLoadedEventName));
  window.abReaderInitialized = true;
};

setInitialize(initialize);
